@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
.updown-animation{
    animation-name: infiniteupdown;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all .3s;
}
@keyframes infiniteupdown{
    0%{
        transform: scale(1) ;

    }

    50%{
        transform: scale(1.1) ;
    }

    100%{
        transform: scale(1) ;
    }
}

.title{
    color: #0069D2;
    font-family: inter, "sans-serif";
    font-weight: 700;
    font-size: 48px;
}
.btnh1{
    outline-color: 2px solid #0069D2;
    background-color: transparent;
    color: #0069D2;
    font-family: inter, "sans-serif";
    font-size: 14px;
}
/* @media (max-width:945px){
    .s1text{
        text-align: center;
    }
} */
.services-sec{
    background-color: #DEF6FF;
}
.sec-titleb{
    color: #0069D2;
    font-family: inter,"sans-serif";
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}
.title-sec{
    font-size: 33px;
    font-family: inter, "sans-serif";
    font-weight: 700;
}
.servicecontent{
    display: flex;
    justify-content: space-between;
}
@media (max-width:945px){
    .servicecontent{
        flex-direction: column;
    }
}
.about-sec{
    padding: 70px 15px;
}
.about1{
    position: relative;

}
.about2{
    position: absolute;
    margin-top: 250px;
    margin-left: -200px;
    border: 3px solid white;
    border-radius: 10px;
}
@media (max-width: 586px){
    .about2{
        margin-left: -310px;
    }
}

.innerslider{
    background:
    linear-gradient(to right,
        rgba(0, 44, 91, 0.962),
        rgba(44, 132, 255, 0.9)),
    url(/src/Assets/cbg.jpg);
    min-height: 200px; 
    
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.innerslider h3{
    font-weight: 700;
}
.slider-link{
    padding: 0;
    color: white;
    text-decoration: none;
    
}
.slider-link:hover{
    padding: 0;
    color: #1e1e1e;
    background-color:  #def6ff00;;
    border-radius: 5px;
    
    
}
.our_team{
    background-color: #DEF6FF;
}
.card_team{
   
    background-color: white;
    padding: 30px;
    box-shadow: 0px 10px 10px 0px rgba(16, 15, 15, 0.104);
}
.team_name{
    font-family: poppins ,'sans-serif';
    font-size: 24px;
}
.team_des{
    color: #3c3c3c;
}
.team_in{
    font-size: 30px;
    cursor: pointer;
    color: rgb(179, 143, 70);
}
.team_in:hover{
    color: #004386;
}
.card_founder{
    background-color: white;
    padding: 40px;
    width: fit-content;
    box-shadow: 0px 10px 10px 0px rgba(16, 15, 15, 0.104);

}


.dm_new_sec{
     background-color: #0069D2;
}
.new_inner_sec_li{
    color: white;
    font-size: 20px;
    font-weight: 600;
}
