@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.ourprocess-bg{
    background-color: #DEF6FF;
}
.cardp{
    max-width: 500px;
    height: 240px;
    position: relative;
    text-align: left;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 10px 10px 0px rgba(16, 15, 15, 0.104);
    z-index: 1;
}

.lines{
    position: absolute;
    margin-top: -100px;
    max-width: 18%;

}
.lines2{
    position: absolute;
    margin-top: -120px;
    max-width: 16%;
    margin-left: -20px;

}
.lines5{
    position: absolute;
    margin-top: -120px;
    max-width: 20%;
    margin-left: -40px;

}
.lines4{
    position: absolute;
    margin-top: -170px;
    max-width: 25%;
    max-height: 30%;
    margin-left: -90px;


}
.lines3{
    position: absolute;
    margin-top: -100px;
    max-width: 16%;
    margin-left: -480px;


}
.mobilelines{
    display: none;
}
@media (max-width:1500px){
    .lines{
        position: absolute;
        margin-top: -100px;
        max-width: 20%;
    
    }
    .lines2{
        position: absolute;
        margin-top: -80px;
        max-width: 22%;
        margin-left: -40px;
    
    }
    .lines4{
        position: absolute;
        margin-top: -150px;
        max-width: 5%;
        margin-left: -90px;
    
    
    }
    .lines3{
        position: absolute;
        margin-top: -100px;
        max-width: 18%;
        margin-left: -400px;
    
    
    }
    .lines5{
        position: absolute;
        margin-top: -100px;
        max-width: 22%;
        margin-left: -10px;
    }
    
}


@media (max-width:990px){
    .lines{
        display: none;
    }
    .lines2{
        display: none;
    }
    .lines4{
        display: none;
    }
    .lines3{
        display: none;
    }
    .lines5{
        display: none;
    }
    
    .mobilelines{
        display: block;
        position: absolute;
        margin-top: -120px;
        margin-left: 150px;
        max-width: 18%;

    }
}

@media (min-width:468px) and (max-width:900px){
    .mobilelines{
        display: block;
        position: absolute;
        margin-top: -120px;
        margin-left: 220px;
        max-width: 18%;

    }
}