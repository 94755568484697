@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.footerbg{
    background-color: #000D1B;
    color: white;
}
.footer1 p{
    color: #BCBCBC;
    text-decoration: none;
    font-size: 14px;
}
.footerlink{
    color: #BCBCBC;
    text-decoration: none;
    font-size: 14px;
}
.footerm{
    margin-bottom: 6px;
}
.socialicons{
    margin: 15px 0 10px;
}
ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }
.socialicons ul{
    list-style: none;

}
.socialicons ul li{
    display: inline-flex;
    margin-right: 15px;
}
.socialicons ul li a{
    display: inline-flex;

    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: #D9D9D9;
    border-radius: 50%;
    border: none;
    font-size: 18px;
    background-color: #d9d9d928;
}
.socialicons ul li a:hover{
    color: white;
    background-color: #0069D2;
}

.footericons{
    font-size: 12px;
    color: #0069D2;
}
.footericon{
    font-size: 30px;
    color: #0069D2;
}
@media (max-width:698px) and (min-width:390px){
    .footericon{
        font-size: 12px;
    }
}
.footer-flex-box{
    display: flex;
    justify-content: center;
}
.footer-flex-box2{
    display: flex;
    padding-left: 60px;
}
.lastfooter{
    background-color: #000000;
}
@media (max-width:990px){
    .footer-flex-box{
        margin-top: 30px;
        justify-content: flex-start;
    }
    .footer-flex-box2{
        margin-top: 30px;
        padding-left: 12px;
    }
}