
.counterbg{
    background:
    linear-gradient(to right,
        rgba(0, 105, 210, 0.9),
        rgb(0, 105, 210, 0.9)),
    url(/src/Assets/cbg.jpg);
    min-height: 200px; 

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

   
}
.numbercounter{
    font-family: inter, "sans-serif";
    font-weight: 700;
    margin: 0;
}
.counterc{
    opacity: 1.0;
}