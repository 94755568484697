@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
.header{
    background-color: #0069D2;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    
}
.modal-header .btn-close:focus {
    box-shadow: none;
}
.header-icons{
    margin-right: 8px;
    cursor: pointer;
    color: white;
}
.text-h{
    margin: 0;
}
.h-sec2{
    justify-content: end;
}
.nav-link{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: black;
    font-weight: 400;
    padding: 0.5rem;
    margin-left: 10px;
    margin-right: 10px;
}
.nav-link:hover{
    color: #0069D2;
    background-color:  #DEF6FF;;
    border-radius: 5px;
}
.navbar-nav .nav-link.active{
    color: #0069D2;
    background-color: #DEF6FF;
    border-radius: 4px;
}
.btn{
    font-size: 14px;
    background-color: #0069D2;

    border-radius: 3px;
}
.btn:hover{
    font-size: 14px;
    background-color: #004c98;
}
@media (max-width:945px){
    .navbar-collapse {
        padding-top: 20px;
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
    }
    .h-sec1{
        justify-content: center;
    }
    .h-sec2{
        justify-content: center;
    }
}

.navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: var(--bs-navbar-color);
    background-color: transparent;
    /* border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color); */
    border: none;
    /* border-radius: var(--bs-navbar-toggler-border-radius); */
    transition: var(--bs-navbar-toggler-transition);
}
.navbar-toggler:focus{
    box-shadow: none;
}

/* dropdown */

.dropbtn {
    border: none;
    background-color: transparent;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-menu {
  
    border-radius: 10px;
    display: none;
    position: absolute;
    background-color: #fffdfd;
    font-size: 14px;
    min-width: 180px;
    box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.2);
    z-index: 2;
  }
  @media (max-width:945px){
    .dropdown-menu{
        position: relative;
        top: 0;
        border-radius: none;
        box-shadow: none;
    }
    .dropdown-menu a{
        margin-left: 20px;
    }
  }
  
  .dropdown-menu a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-menu a:hover {color: #0069D2; background-color: #DEF6FF; }
  
  /* .dropdown:hover .dropdown-menu{display: block;} */
  
  .dropdown:hover .dropbtn {background-color: #DEF6FF; color: #0069D2;}
  
  @media all and (min-width: 945px){
    .dropdown:hover .dropdown-menu{display: block;}
  }
