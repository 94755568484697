@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.Testimonials{
    font-family: inter, "sans-serif";
    color: black;
}
 
 
  
.container1 {
    overflow: hidden;
    .slider {
      animation: slidein 30s linear infinite;
      white-space: nowrap;
      .logos {
        width: 100%;
        display: inline-block;
        margin: 0px 0;
        .fab {
          width: calc(100% / 5);
          animation: fade-in 0.5s 
            cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
        }
      }
    }
  }

  @keyframes slidein {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width:990px){
      
.container1 {
    overflow: hidden;
    .slider {
      animation: slidein 10s linear infinite;
      white-space: nowrap;
      .logos {
        width: 100%;
        display: inline-block;
        margin: 0px 0;
        .fab {
          width: calc(100% / 5);
          animation: fade-in 0.5s 
            cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
        }
      }
    }
  }
  }