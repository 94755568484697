html,
body {
  margin: 0%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

/* @font-face */

/* CSS Variables */

:root {
  --primary-color: #4458dc;
  --primary-color2: #854fee;
  --line-color: #212529;
  --title-color: #141313;
  --text-color: #777777;
  --border-color: #201f1f17;
  --gradient-color: linear-gradient(90deg, #4458dc 0%, #854fee 100%);
  --shadow-color: 0px 10px 30px rgba(118, 85, 225, 0.3);
  --box-shadow: 0px 10px 30px rgba(57, 56, 61, 0.205);

  /* font family variables. */
  --roboto: "Roboto", cursive;
}