/* techpro.css */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.bgpro {
  background-color: #0069d2;
}

.techpro-sec {
  color: white;
}

.sec-titlew {
  color: white;
  margin: 0%;
  font-family: inter, "sans-serif";
  font-size: 16px;
  font-weight: 400;
}

.lineimgw {
  fill: white;
}

.item1 img {
  vertical-align: middle;
  transition: all 0.5s;
}

.menu-tab {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.btntp:hover{
  background-color: white; /* Change this to the desired active button color */
  color: #0069d2;
}
.active-btn {
  background-color: white; /* Change this to the desired active button color */
  color: #0069d2;
}
